import { gsap } from "gsap";
    
import { CustomEase } from "gsap/CustomEase";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";

import { header } from "./header.js"
import { imageAni } from "./homeAni.js"




;(function () {
    
  
    console.log("CHECK && loadAllFunctions");
    gsap.registerPlugin(ScrollTrigger,Observer,ScrollToPlugin,Draggable,CustomEase);
    header();




    //--vh INNER HEIGHT VARIABLE
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--vh', window.innerHeight/100 + 'px');
        console.log("var --vh SET")
        }
        window.addEventListener('resize', appHeight)
        appHeight();
   
    
    //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION
    //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION   //MENU AND NAVIGATION

    //HAMBURGER
    const hamburger = document.getElementById("hamburger");

    hamburger.addEventListener("click", function() {
    if (!this.classList.contains("active")) {
        gsap.to(".line2:nth-child(1)", { transformOrigin: "50% 50%",duration: 0.25, y: 5, onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.5,rotation: 225}) });
        gsap.to(".line2:nth-child(2)", { transformOrigin: "50% 50%",duration: 0.25, y: -5, onComplete: ()=> gsap.to(".line2:nth-child(2)", { duration: 0.5,rotation: -45}) });
      
    } else {
        gsap.to(".line2:nth-child(1)", { transformOrigin: "50% 50%",duration: 0.5, rotation: 0, onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.5, y: 0}) });
        gsap.to(".line2:nth-child(2)", { transformOrigin: "50% 50%",duration: 0.5, rotation: 0, onComplete: ()=> gsap.to(".line2:nth-child(2)", { duration: 0.5, y: 0}) });
        // gsap.to(".line2:nth-child(2)", { transformOrigin: "50% 50%",duration: 0.25, y: 0, onComplete: ()=> gsap.to(".line2:nth-child(2)", { duration: 0.3,rotation: 0}) });
        // gsap.to(".line2:nth-child(1)", { transformOrigin: "50% 50%",duration: 0.25, y: 0, onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.3,rotation: 0}) });
        // gsap.to(".line2:nth-child(2)", { transformOrigin: "50% 50%",duration: 0.25, y: 0, onComplete: ()=> gsap.to(".line2:nth-child(2)", { duration: 0.3,rotation: 0}) });
    }
    this.classList.toggle("active");
    });

    //Function to reverse the hamburger animation 
    function reverseHamburgerAnimation() {
        gsap.to(".line2:nth-child(1)", { transformOrigin: "50% 50%",duration: 0.5, rotation: 0, onComplete: ()=> gsap.to(".line2:nth-child(1)", { duration: 0.5, y: 0}) });
        gsap.to(".line2:nth-child(2)", { transformOrigin: "50% 50%",duration: 0.5, rotation: 0, onComplete: ()=> gsap.to(".line2:nth-child(2)", { duration: 0.5, y: 0}) });
    hamburger.classList.remove("active");
    }
    
    // Function to toggle menu
    // function toggleMenu() {
    //     var menu = document.querySelector('.menu');
    //     var mainContent = document.querySelector('#page-wrapper');
    //     var innerContainer = document.querySelector('.inner-container');
    //     menu.classList.toggle('show');

    //     // Animation using GSAP
    //     if (menu.classList.contains('show')) {
    //         gsap.set('body', { overflow: 'hidden', height: 100 + "vh"});
    //         gsap.to('#smooth-wrapper', {autoAlpha: 0.05, duration: 0.5})
    //         gsap.to(menu, {autoAlpha:1, delay: 0.25, duration: 0.75})
    //     } else {
    //         gsap.set('body', { overflow: 'unset'});
    //         gsap.to('#smooth-wrapper', {autoAlpha: 1,  duration: 0.5})
    //         gsap.to(menu, {autoAlpha:0, delay: 0.25, duration: 0.75})
    //     }
    // }


   
    window.addEventListener("scroll", () => {
        document.documentElement.style.setProperty(
          "--scroll-y",
          `${window.scrollY}px`
         
        );

       
      });



    function toggleMenu() {

        // const showDialog = () => {
        //     document.getElementById("dialog").classList.add("show");
        //     const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
        //     const body = document.body;
        //     body.style.position = "fixed";
        //     body.style.top = `-${scrollY}`;
        //   };
        //   const closeDialog = () => {
        //     const body = document.body;
        //     const scrollY = body.style.top;
        //     body.style.position = "";
        //     body.style.top = "";
        //     window.scrollTo(0, parseInt(scrollY || "0") * -1);
        //     document.getElementById("dialog").classList.remove("show");
        //   };
        
    
        console.log( `${window.scrollY}px`);

        var menu = document.querySelector('.menu');
        var mainContent = document.querySelector('#page-wrapper');
        var innerContainer = document.querySelector('.inner-container');
        var scrollPosition = window.pageYOffset; // Store current scroll position
    
        menu.classList.toggle('show');
    
        // Animation using GSAP
        if (menu.classList.contains('show')) {

            const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
            const body = document.body;
            body.style.position = "fixed";
            body.style.top = `-${scrollY}`;
            // gsap.set('body', { overflow: 'hidden', height: '100vh' });
            gsap.to('#smooth-wrapper', { autoAlpha: 0, duration: 0.5,
                    onComplete: () => {
                        gsap.to(menu, { autoAlpha: 1, delay: 0.25, duration: 1 });
                    } });
           
        } else {
            // gsap.set('body', { overflow: 'unset' });
            const body = document.body;
            body.style.position = "";
            body.style.top = "";
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
            gsap.to(menu, { autoAlpha: 0, delay: 0.25, duration: 1,
                onComplete: () => {
                    gsap.to('#smooth-wrapper', { autoAlpha: 1, duration: 0.5, delay: 0.5 });
                } });
           
          
            window.scrollTo(0, scrollPosition); // Restore scroll position
        }
    }

 

    // Function to close menu
    function closeMenu() {
        var menu = document.querySelector('.menu');
        var mainContent = document.querySelector('#page-wrapper');
        menu.classList.remove('show');
        // gsap.set('body', { overflow: 'unset'});
        gsap.to(mainContent, { translateY: "0", scaleX: 1, duration: 0.8, delay: 0.15, ease: CustomEase.create("custom", "M0,0 C0,0 0.28,0.003 0.373,0.025 0.503,0.055 0.55,0.234 0.71,0.672 0.84,1.031 1,1 1,1 ") }); // Move main content back up and restore width
    }

    // Event listener for menu toggle button
    document.getElementById('hamburger').addEventListener('click', toggleMenu);



    // // Event listener for window scroll
    // window.addEventListener('scroll', function() {
    //     var menu = document.querySelector('.menu');
    //     //if (menu.classList.contains('show') && window.scrollY > 0) {
    //     if (menu.classList.contains('show')) {
    //         reverseHamburgerAnimation();
    //         toggleMenu(); // Close menu if open and user scrolls down
            
    //     }
    // });

    // // Event listener for scroll within main content
    // document.getElementById('page-wrapper').addEventListener('scroll', function() {
    //     var menu = document.querySelector('.menu');
    //     if (menu.classList.contains('show')) {
    //         reverseHamburgerAnimation();
    //         closeMenu(); // Close menu if open and user scrolls within main content
    //     }
    // });


   

    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU   
    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU    // END HAMBURGER AND MENU   

















    document.addEventListener("DOMContentLoaded", (event) => {
    
        
   

const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
console.log(toggleSwitch);


imageAni();




// DARK BUTTON
$(".nbb_btn-dk").on("click", function (e) {
  e.preventDefault();
  $('#animation').toggleClass('tag nacht');

  $(this).attr("aria-pressed", $(this).attr("aria-pressed") === "false" ? "true" : "false");
  
	$("html").attr("data-theme",$("html").attr("data-theme") === "dark" ? "light" : "dark"
  );

  if ("true" === $(this).attr("aria-pressed")) {
    localStorage.setItem("themeChoice", "dark");
	  document.documentElement.classList.add('color-theme-transition');
	  $(".nbb_btn-lt").attr("aria-pressed", "false");
	   document.getElementById('nbb_btn-light').classList.remove('active');
	   document.getElementById('nbb_btn-dark').classList.add('active');
     
	   toggleSwitch.checked = true;
	  
  } else {
    localStorage.setItem("themeChoice", "light");
	  $(".nbb_btn-lt").attr("aria-pressed", "true");
	  document.documentElement.classList.add('color-theme-transition');
	   document.getElementById('nbb_btn-light').classList.add('active');
	  document.getElementById('nbb_btn-dark').classList.remove('active');
     
	  toggleSwitch.checked = false;
  }
//console.log(localStorage);
  //console.log("themeChoice");
  //console.log("set - " + localStorage.getItem("themeChoice"));
});




//LIGHT BUTTON

$(".nbb_btn-lt").on("click", function (e) {
  e.preventDefault();
  $(this).attr("aria-pressed", $(this).attr("aria-pressed") === "true" ? "false" : "true");
	$("html").attr("data-theme",$("html").attr("data-theme") === "light" ? "dark" : "light"
  );
  $('#animation').toggleClass('nacht tag');

  if ("true" === $(this).attr("aria-pressed")) {
    localStorage.setItem("themeChoice", "light");
	  document.documentElement.classList.add('color-theme-transition');
	  $(".nbb_btn-dk").attr("aria-pressed", "false");
	   document.getElementById('nbb_btn-light').classList.add('active');
	   document.getElementById('nbb_btn-dark').classList.remove('active');
	   toggleSwitch.checked = false;
	  
  } else {
    localStorage.setItem("themeChoice", "dark");
	  document.documentElement.classList.add('color-theme-transition');
	  $(".nbb_btn-dk").attr("aria-pressed", "true");
	   document.getElementById('nbb_btn-light').classList.remove('active');
	  document.getElementById('nbb_btn-dark').classList.add('active');
     
	  toggleSwitch.checked = true;
  }
console.log(localStorage);
  console.log("themeChoice");
 
});













// TOOGLE SWITCH

function switchTheme(e) {
    if (e.target.checked) {
				
			  document.documentElement.classList.add('color-theme-transition');
        		document.documentElement.setAttribute('data-theme', 'dark');
				localStorage.setItem("themeChoice", "dark")
				$(".nbb_btn-dk").attr("aria-pressed", "true");
		 		$(".nbb_btn-lt").attr("aria-pressed", "false");
			  document.getElementById('nbb_btn-dark').classList.add('active');
	      document.getElementById('nbb_btn-light').classList.remove('active');
           
			
        		
    }
    else {        
			    	document.documentElement.classList.add('color-theme-transition');
			    	document.documentElement.setAttribute('data-theme', 'light');
					 localStorage.setItem("themeChoice", "light")
					$(".nbb_btn-dk").attr("aria-pressed", "false");
		 			$(".nbb_btn-lt").attr("aria-pressed", "true");
					document.getElementById('nbb_btn-light').classList.add('active');
					document.getElementById('nbb_btn-dark').classList.remove('active');
          
					
          			
    }
	
	
}

toggleSwitch.addEventListener('change', switchTheme, false);





var isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

if ("dark" === localStorage.getItem("themeChoice")) {
  isDarkMode = true;
	document.getElementById('nbb_btn-light').classList.remove('active');
	  document.getElementById('nbb_btn-dark').classList.add('active');
    document.getElementById('animation').classList.add('nacht');
	
} else if ("light" === localStorage.getItem("themeChoice")) {
  isDarkMode = false;
	 document.getElementById('nbb_btn-light').classList.add('active');
	  document.getElementById('nbb_btn-dark').classList.remove('active');
    document.getElementById('animation').classList.add('tag');
	
}

if (isDarkMode) {
  $("html").attr("data-theme", "dark");
  $(".nbb_btn-dk").attr("aria-pressed", "true");
  $(".nbb_btn-lt").attr("aria-pressed", "false");
  document.getElementById('nbb_btn-light').classList.remove('active');
  document.getElementById('nbb_btn-dark').classList.add('active');
  document.getElementById('animation').classList.add('nacht');
  
  toggleSwitch.checked = true;
} else {
  $("html").attr("data-theme", "light");
  $(".nbb_btn-dk").attr("aria-pressed", "false");
  $(".nbb_btn-lt").attr("aria-pressed", "true");
  document.getElementById('nbb_btn-light').classList.add('active');
  document.getElementById('nbb_btn-dark').classList.remove('active');
  document.getElementById('animation').classList.add('tag');

  toggleSwitch.checked = false;
}










document.documentElement.classList.add('color-theme-transition');
 setTimeout(function(){
          document.documentElement.classList.remove('color-theme-transition');
         
    }, 2000);
	


// NAVIGATION LOGO and HEADING LINE move on scroll




    //SCROLL POSITION FOR GOING BACK
    // var scrollCont = document.getElementById("smooth-wrapper");
    // console.log(scrollCont);
    // scrollCont.addEventListener("scroll", event => {
    //     let scrollPos = (`${scrollCont.scrollTop}`);
    //     console.log(scrollPos);
    // }, { passive: true });
    

var num = 150; //number of pixels before modifying styles 
var bt1 = document.getElementById("nbb_btn-dark");
var bt2 = document.getElementById("nbb_btn-light");
var scrollCont = document.getElementById("smooth-content");

$(scrollCont).bind('scroll', function () {

     

if ($(scrollCont).scrollTop() < num) {

  bt1.classList.add("is__fixed");
  bt1.classList.remove("is__active");
  bt2.classList.add("is__fixed");
  bt2.classList.remove("is__active");
   

} else {
  bt1.classList.remove("is__fixed");
  bt1.classList.add("is__active");
  bt2.classList.remove("is__fixed");
  bt2.classList.add("is__active");

}
}); //





    








 function fadeInFirstSection(elem_firstsection) {

    if(elem_firstsection.classList.contains("firstsection")) {
      console.log("FIRST SECTION ERKANNT");
      gsap.to (".sec-title.anim", { autoAlpha:1, ease: "power4.out", duration: 1.5, delay: 0.3 }); 
    }
  
  };  //END fadeInFirstSection //
  
  gsap.utils.toArray(".firstsection").forEach(function(elem_firstsection) {
    ScrollTrigger.create({
      trigger: elem_firstsection,
         onEnter: function() { fadeInFirstSection(elem_firstsection) }, 
    });
  });
  
  
  
  
  
  //// Sticky Intro ////
  
  function workIntroSticky(elem_introBG) {
   
  if(elem_introBG.classList.contains("intro")) {
    
  
  gsap.to (".introBG", {
    autoAlpha:1,
    duration: 2,
    //ease: "power4.out", 
    delay: 0.5,
    
  });  
  gsap.to (".intro-overlay", {
    autoAlpha:0.25,
    duration: 2,
    //ease: "power4.out", 
    delay: 0.5,
    
  }); 
  
  
  
  gsap.to(elem_introBG, {
      yPercent:-75,
      autoAlpha:0,
      ease: "none",
            scrollTrigger: {
            scroller: "#smooth-content",
            trigger: elem_introBG,
            id: "elem_ImageBig",
            start: ()=> "top top",
            end: ()=>  "125%",
            scrub: true,
           // markers: true,
            pin:true,
            pinType: "fixed",
            anticipatePin: true,
           pinSpacing:false,
            }
  })
  
  
//   gsap.to(".sec-title.anim", {
//       yPercent:-50,
//       //scale:1.02,
//       //autoAlpha:0,
//       ease: "none",
//             scrollTrigger: {
//             // scroller: "#page-wrapper",
//             trigger: elem_introBG,
//             id: "elem_ImageBig_Title",
//             start: ()=> "top top",
//             end: ()=>  "100%",
//             scrub: true,
//             //markers: true,
//         //     pin:true,
//         //     pinType: "fixed",
//         //    anticipatePin: true,
//         //     pinSpacing:false,
            
//             }
  
//           })
        }
  
    // project tile animation
    select = e => document.querySelector(e);
    selectAll = e => document.querySelectorAll(e);
    const proj_title = selectAll(".spanline-inner");
    let tlPT = gsap.timeline({
              scrollTrigger: {
              id: "textFadeUpIn",
              trigger: ".spanline-inner",
              toggleActions: "play none none none",
              //markers: true,
              
          }
      });
  
      tlPT.from(proj_title, {
      duration: 0.75,
      yPercent: 100,
     
      opacity: 0,
      stagger:.25,
     
      });
  
  };	// END workIntroSticky
  
  gsap.utils.toArray(".nbb-sec.intro").forEach(function(elem_introBG) {
    ScrollTrigger.create({
      trigger: elem_introBG,
         onEnter: function() { workIntroSticky(elem_introBG) }, 
    });
  });
  
  
  
  
  
      /// WORK TEASER IMAGE Fullscreen
  
  
      function workTeaser(elem_teaser) {
        
        if(elem_teaser.classList.contains("teaser-reveal")) {
         
        gsap.set (elem_teaser, {
        
          scale: 0.75  
  
        });      
      
        gsap.to(elem_teaser, {
            
            scale:1,
            autoAlpha: 1,
           
            ease: "none",
                  scrollTrigger: {
                  trigger: elem_teaser,
                  id: "elem_teaser",
                  start: ()=> "top bottom",
                  end: ()=>  "top 25%",
                  scrub: true,
                  //markers: true,
                  //pin:true,
                  //anticipatePin: true,
                  //pinSpacing:false,
                  }
                })
              }
              };	
         
      
      
        gsap.utils.toArray(".teaser-reveal").forEach(function(elem_teaser) {
              
              
          ScrollTrigger.create({
            trigger: elem_teaser,
           
            onEnter: function() { workTeaser(elem_teaser) }, 
                    
          });
          });
      
   
  
  
  
   // TEASER CONTAINER Sticky //
  
          function workTeaserCon(elem_teaserCon) {
        
            if(elem_teaserCon.classList.contains("teaser")) {
                  
             gsap.set (elem_teaserCon, {
              //yPercent: 0.1,
              //scale: 1.05,  
            });
            gsap.set (".teaser", {
              //yPercent: 0.1,
              //scale: 0.75  
            });      
          
          
            gsap.to(elem_teaserCon, {
                //backgroundPosition: () => "0% 100%",
                //yPercent:-75,
                //scale:1,
                //autoAlpha:0,
          
                
                ease: "none",
                      scrollTrigger: {
                      trigger: elem_teaserCon,
                      id: "elem_teaserCon",
                      //start: ()=> "top bottom",
                      //end: ()=>  "top 50%",
                      //scrub: true,
                      //markers: true,
                      pin:true,
                      pinType: "fixed",
                      anticipatePin: true,
                      pinSpacing:false,
                      }
                    })
                  }
                  };	
           
          
            gsap.utils.toArray(".teaser").forEach(function(elem_teaserCon) {
                  
                  
              ScrollTrigger.create({
                trigger: elem_teaserCon,
               
                onEnter: function() { workTeaserCon(elem_teaserCon) }, 
                        
              });
              });
  




// function nextCaseFixed(elem_nextCase) {
  
//     var nextCaseInner = document.querySelector(".next-case-inner");
     
//     //console.log(nextCaseInner);
  
//     // gsap.set(nextCaseInner, {
//     //   yPercent: 80,
//     //   //opacity: 0,
  
//     // });
  
//     if(elem_nextCase.classList.contains("next-case")) {
          
//      gsap.set (elem_nextCase, {
//       yPercent: -100,
//     });  
    
//     gsap.to(elem_nextCase, {
//         //backgroundPosition: () => "0% 100%",
//         //yPercent:0,
//         //scale:1.02,
//         //autoAlpha:0,
  
        
//         //ease: "none",
//              scrollTrigger: {
//               trigger: elem_nextCase,
//               id: "elem_nextCase",
//               //start: ()=> "top bottom",
//               //end: ()=>  "200%",
//               //scrub: true,
//               //markers: true,
//               pin:true,
//               anticipatePin: true,
//               pinSpacing:false,
//               }
   
  
    
//           })
          
          
//     // gsap.to(".next-case-inner", {
//     //         yPercent:0,
//     //         scale:1.1,
            
//     //         //ease: "none",
//     //              scrollTrigger: {
//     //               trigger: elem_nextCase,
//     //               id: "elemNEXTCASE innere",
//     //               //start: ()=> "top 50%",
//     //               //end: ()=>  "top top",
//     //               scrub: true,
//     //               //markers: true,
//     //               //pin:true,
//     //               //anticipatePin: true,
//     //               //pinSpacing:false,
//     //               }
//     //             })
  
  
//               }
//               };	
  
      
//     gsap.utils.toArray(".next-case").forEach(function(elem_nextCase) {
          
          
//     ScrollTrigger.create({
//       trigger: elem_nextCase,
     
//       onEnter: function() { nextCaseFixed(elem_nextCase) }, 
              
//     });
//     });
  






    


    }); // END OF DOM LOADED EVENT


})(); //global END