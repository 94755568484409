import logoURL from '../assets/logo/logo.png';

export function header() {


console.log(logoURL);
document.getElementById("main-header").innerHTML = "<div class='hamburger' id='hamburger'>"


// +   "<nav class='nav'>"
// +       "<ul>"
// +           "<li>ART</li>"
// +           "<li>Publication</li>"
// +           "<li>Contact</li>"
// +       "</ul>"
// +  " </nav>"

// +   "<div id='clock'><span><h6>Munich</span></span><span><h6 id='date-time'></h6></span></div>"

    // +"     <div class='hamburger' id='hamburger'>"
   
    +"         <div class='line2'><div></div></div>"
    +"         <div class='line2'><div></div></div>"
    +"       </div>"
   
    +" </div>"
    + "<div class='logo-header'><img src=\"/assets/logo/logo.png\"></div>"
    +" <div class='menu backgrTrans'>"
    +"    <div class='menu-wrapper'>"
    +"         <div class='menu-row'>"
    +"                     <div class='col-1-2'>"
    +"                         <a href='/'><h1>ART</h1></a>"
    +"                         <a href='/'><h1>H-LIFE</h1></a>"
    +"                         <a href='/about.html'><h1>ABOUT</h1></a>"
    +"                         <a href='/contact/index.html'><h1>CONTACT</h1></a>"
    +"                     </div>" //END COL
    +"                     <div class='col-1-2'>"
 
    // +"                         <div class='language-switch'>"
    // +"                             <ul>"
    // +"                                 <li><a href='#' class='lbt' data-lng='en'>ENG</a></li><span> / </span>"
    // +"                                  <li><a href='#' class='lbt' data-lng='de'>DEU</a></li><span> / </span>"
    // +"                                 <li><a href='#' class='lbt' data-lng='es'>ESP</a></li>"
    // +"                             </ul>"
    // +"                         </div>"
    // +"                     <div class='indexmenu'>"
    // +"                         <div class='theme-switch'>"
    // +"                             <input type='checkbox' id='dark-mode-switch' />"
    // +"                             <label for='dark-mode-switch'><h6></h6></label>"
    // +"                         </div>"
    // +"                     </div>"
    +"                </div>" //END COL

   
    +"         </div>"
    +"     </div>"
    +"     </div>"
    +"         <div class='dark-light_switch-mobile' style='visibility:hidden;'>"
    +"             <div class='theme-switch-wrapper' >"
    +"                 <label class='theme-switch' for='checkbox' >"
    +"                     <input type='checkbox' id='checkbox' class='btn' aria-pressed='false'  />"
    +"                     <div class='switch round'></div>"
    +"                 </label><em></em>"
    +"             </div>"
    +"         </div>"
    +"         <div class='nbb_btn-dark_wrapper'><button id='nbb_btn-dark' class='nbb_btn nbb_btn-dk btn' aria-pressed='false'>NACHTANSICHT</button></div>"
    +"         <div class='nbb_btn-light_wrapper'><button id='nbb_btn-light' class='nbb_btn nbb_btn-lt active btn' aria-pressed='true'>TAGANSICHT</button></div>"


    +" </div>" // END HEADER CONTAINER
  } 