import { gsap, ScrollTrigger } from "gsap/all";


export function bgChange() {
    var docStyle = getComputedStyle(document.documentElement); 
    var checkMode = localStorage.getItem("themeChoice");
    console.log("checkMode:", checkMode); // Add this line to check the value
    const imageLink = document.querySelectorAll(".whover");
    // console.l
    var bgColorVaribale = docStyle.getPropertyValue("--bg-color");
    
    if (checkMode == "dark") {
        gsap.set((".main"),{ backgroundColor: "#16191b", duration:0.6,repeatRefresh: true, });
    
    } else if (checkMode == "light") {
        gsap.set((".main"),{ backgroundColor: "#fbfbf9", duration:0.6,repeatRefresh: true, });
    
    };

    gsap.utils.toArray(".whover").forEach(a => {
        const bgColorDark = a.dataset.colordark;
        const bgColorLight = a.dataset.colorlight;
        const crsColor = a.dataset.crs;

        a.addEventListener("mouseenter", () => {
            document.querySelector(".main").style.backgroundColor = checkMode == "dark" ? bgColorDark : bgColorLight;
            document.querySelector(".crs").style.backgroundColor = crsColor;
            a.classList.toggle("active");
            // var inners =  a.getElementsByClassName("image-wrapper");
            // console.log(inners);
            // for (let i = 0; i < inners.length; i++) {
            //     inners[i].classList.toggle("active");
            // }
            
        });

        a.addEventListener("mouseleave", () => {
            document.querySelector(".main").style.backgroundColor = checkMode == "dark" ? "#16191b" : "#fbfbf9";
            document.querySelector(".crs").style.backgroundColor = "transparent"; // Or your default cursor color
            a.classList.toggle("active");
            // var inners =  a.getElementsByClassName("image-wrapper");
            // console.log(inners);
            // for (let i = 0; i < inners.length; i++) {
            //     inners[i].classList.toggle("active");
            // }
        });

        // CURSOR
        let hover_mouse = gsap.to(".crs", { backgroundColor: `${crsColor}`, opacity: 1, scale: 1, duration: 0.2, paused: true });
                
        a.addEventListener("mouseenter", () => hover_mouse.play());
        a.addEventListener("mouseleave", () => hover_mouse.reverse());

        const crs2 = document.querySelector(".crs");
        const crs2place = crs2.querySelector(".crs span");
        const linkMessage = document.querySelectorAll("a[data-cont]");
        linkMessage.forEach(message => {
            message.addEventListener("mouseover", function() {
                crs2place.innerHTML = message.getAttribute("data-cont");
            });
            message.addEventListener("mouseout", function() {
                crs2place.innerHTML = "";
            });
        });

    });

    gsap.set(".crs", { xPercent: -75, yPercent: -75 });
    
    const crs = document.querySelector(".crs");
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.35;
    
    const xSet = gsap.quickSetter(crs, "x", "px");
    const ySet = gsap.quickSetter(crs, "y", "px");
    
    window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
    });
    
    gsap.ticker.add(() => {
        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
                    
        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
    });
}




// var tid = setInterval( function () {
//     if ( document.readyState !== 'complete' ) return;
//     clearInterval( tid );       
//     // do your work

//ANIMATIONEN IMAGES

export function imageAni() {


    var d = window.matchMedia("(min-width: 1025px)");
    var tl = window.matchMedia("(min-width:950px) and (max-width: 1024px) and (orientation: landscape)");
    var tp = window.matchMedia("(min-width: 768px) and (orientation: portrait)");
    var ml = window.matchMedia("(min-width: 768px) and (max-width: 1023px) and (orientation: landscape) ");
    var mp = window.matchMedia("(min-width: 320px) and (max-width: 767px) and (orientation: portrait) ");


    if (d.matches || tl.matches || tp.matches) {

        // alert("DESKTOP JS ADJUST");
        function imageAniFullTB(elem_tnImage) {
            var innerContainer = document.querySelector('.inner-container');
            if(elem_tnImage.classList.contains("thumb_move")) {
                gsap.set(elem_tnImage, { yPercent: -15 });     
                gsap.to(elem_tnImage, {yPercent: 20,ease: "none",
                         scrollTrigger: {  
                         trigger:elem_tnImage,
                         scroller: "#smooth-content",
                         id: "elem_tnImage2",
                         start: ()=> "top bottom",
                         end: ()=>  "200%",
                         invalidateOnRefresh: true,
                         //onUpdate: function(self) {
                            // Update ScrollTrigger when scrolling within the inner container
                           // self.scroll(self.scroll().x, innerContainer.scrollTop); },
                         scrub: true,
                         //pin: true,
                         //markers: true,
                        }
                })
            }
        };	
        function ThumbMove() {
           gsap.utils.toArray(".thumb_move").forEach(function(elem_tnImage) {
                ScrollTrigger.create({
                    trigger: elem_tnImage,
                    scroller: "#smooth-content",
                    invalidateOnRefresh: true , 
                    onEnter: function() { imageAniFullTB(elem_tnImage) }, 
                });
            });
         }
         ThumbMove();









    // } else if (tl.matches) {

        // alert("TABLET LANDSCAPE JS ADJUST");


    // } else if (tp.matches) {

        // alert("TABLET PORTRAIT JS ADJUST");

    // } else if (ml.matches) {

        // alert("MOBILE LANDSCAPE JS ADJUST");


    } else if (mp.matches || tp.matches) {
        // alert("MOBILE PORTRAIT JS ADJUST");
        function imageAniFullTB(elem_tnImage) {
            var innerContainer = document.querySelector('.inner-container');
            if(elem_tnImage.classList.contains("thumb_move")) {
                gsap.set(elem_tnImage, { yPercent: -10 });     
                gsap.to(elem_tnImage, {yPercent: 15,ease: "none",
                         scrollTrigger: {
                         trigger:elem_tnImage,
                         scroller: "#smooth-content",
                         id: "elem_tnImage2",
                         start: ()=> "top bottom",
                         end: ()=>  "200%",
                         invalidateOnRefresh: true,
                         //onUpdate: function(self) {
                            // Update ScrollTrigger when scrolling within the inner container
                            //self.scroll(self.scroll().x, innerContainer.scrollTop); },
                         scrub: true,
                         //pin: true,
                         //markers: true,
                        }
                })
            }
        };	
        function ThumbMove() {
           gsap.utils.toArray(".thumb_move").forEach(function(elem_tnImage) {
                ScrollTrigger.create({
                    trigger: elem_tnImage,
                    scroller: "#smooth-content",
                    invalidateOnRefresh: true , 
                    onEnter: function() { imageAniFullTB(elem_tnImage) }, 
                });
            });
         }
         ThumbMove();




    }






}
  
// function adjustBrightnessAllImages(velocityMultiplier) {
//     // function adjustBrightnessAllImages(velocityMultiplier) {
//         // Get all images
//         const images = document.querySelectorAll(".thumb_move img");
        
//         // Loop through each image and adjust brightness
//         images.forEach(img => {
//             // Calculate brightness based on velocity multiplier
//             const brightness = 1 + (velocityMultiplier - 1) * 0.13; // Adjust the 0.1 factor as needed
            
//             // Apply brightness filter to the image
//             img.style.filter = `brightness(${brightness})`;
//         });
//     }

// function scrollVel() {

   
//     // ScrollTrigger to adjust image brightness based on scrolling velocity
// ScrollTrigger.create({
//     scroller: "#page-wrapper",
//         start: ()=> "top bottom",
//         end: ()=>  "2000%",
//     onUpdate: self => {
//         console.log("scrollVel triggered");
//         // Calculate velocity as the absolute value of the change in scroll position
//         const velocity = Math.abs(self.getVelocity());
        
//         // Call the function to adjust brightness with velocity as multiplier
//         adjustBrightnessAllImages(0.1 * velocity);
//     }
// });
//     }









//});


